import React from "react";
import Loadable from "react-loadable";

import DefaultLayout from "./containers/DefaultLayout";

function Loading() {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      Carregando...
      <img
        alt="loading"
        src="assets/img/loading.gif"
        style={{ width: "75px", height: "75px" }}
      ></img>
    </div>
  );
}

const Dashboard = Loadable({
  loader: () => import("./views/Dashboard"),
  loading: Loading,
});

const User = Loadable({
  loader: () => import("./views/Users/User"),
  loading: Loading,
});

const UserRegister = Loadable({
  loader: () => import("./views/Users/UserRegister"),
  loading: Loading,
});

const ChangePassword = Loadable({
  loader: () => import("./views/Pessoas/access_control/changePassword"),
  loading: Loading,
});

const ChangeDatas = Loadable({
  loader: () => import("./views/Users/ChangeDatas"),
  loading: Loading,
});

const workers_relatorio = Loadable({
  loader: () => import("./views/Pessoas/relatorio"),
  loading: Loading,
});

const workers_cadastro = Loadable({
  loader: () => import("./views/Pessoas/cadastro"),
  loading: Loading,
});

const workers_detalhes = Loadable({
  loader: () => import("./views/Pessoas/informacoes"),
  loading: Loading,
});

const clientesRelatorio = Loadable({
  loader: () => import("./views/ClientHolding/relatorio"),
  loading: Loading,
});

const clientesCadastro = Loadable({
  loader: () => import("./views/ClientHolding/cadastro"),
  loading: Loading,
});

const companies_relatorio = Loadable({
  loader: () => import("./views/Empresas/relatorio"),
  loading: Loading,
});

const companies_cadastro = Loadable({
  loader: () => import("./views/Empresas/cadastro"),
  loading: Loading,
});

const contracted_relatorio = Loadable({
  loader: () => import("./views/Contratadas/relatorio"),
  loading: Loading,
});

const contracted_cadastro = Loadable({
  loader: () => import("./views/Contratadas/cadastro"),
  loading: Loading,
});

const subcontracted_relatorio = Loadable({
  loader: () => import("./views/Subcontratadas/relatorio"),
  loading: Loading,
});

const subcontracted_cadastro = Loadable({
  loader: () => import("./views/Subcontratadas/cadastro"),
  loading: Loading,
});

const supervisions_relatorio = Loadable({
  loader: () => import("./views/Supervisoes/relatorio"),
  loading: Loading,
});

const supervisions_detalhes = Loadable({
  loader: () => import("./views/Supervisoes/informacoes"),
  loading: Loading,
});

const plants_relatorio = Loadable({
  loader: () => import("./views/Empreendimentos/relatorio"),
  loading: Loading,
});

const plants_cadastro = Loadable({
  loader: () => import("./views/Empreendimentos/cadastro"),
  loading: Loading,
});

// const documents_relatorio = Loadable({
//   loader: () => import("./views/Documentos/relatorio"),
//   loading: Loading,
// });

// const documentsActives = Loadable({
//   loader: () => import("./views/DocumentosAtivos/relatorio"),
//   loading: Loading,
// });

const relatorio = Loadable({
  loader: () => import("./views/Relatorio"),
  loading: Loading,
});

const jobs = Loadable({
  loader: () => import("./views/Jobs/relatorio"),
  loading: Loading,
});

const jobs_cadastro = Loadable({
  loader: () => import("./views/Jobs/cadastro"),
  loading: Loading,
});

const actives = Loadable({
  loader: () => import("./views/TipoAtivo/relatorio"),
  loading: Loading,
});

const activesCadastro = Loadable({
  loader: () => import("./views/TipoAtivo/cadastro"),
  loading: Loading,
});

const ativosRelatorio = Loadable({
  loader: () => import("./views/Ativos/relatorio"),
  loading: Loading,
});

const ativosCadastro = Loadable({
  loader: () => import("./views/Ativos/cadastro"),
  loading: Loading,
});

const ativosDetalhes = Loadable({
  loader: () => import("./views/Ativos/informacoes"),
  loading: Loading,
});

const unidade_operacional = Loadable({
  loader: () => import("./views/UnidadeOperacional/relatorio"),
  loading: Loading,
});

const unidade_operacional_cadastro = Loadable({
  loader: () => import("./views/UnidadeOperacional/cadastro"),
  loading: Loading,
});

const plant = Loadable({
  loader: () => import("./views/plants/PlantsList"),
  loading: Loading,
});

const plantRegister = Loadable({
  loader: () => import("./views/plants/PlantRegister"),
  loading: Loading,
});

const anchorList = Loadable({
  loader: () => import("./views/anchor/AnchorList"),
  loading: Loading,
});

const anchorRegister = Loadable({
  loader: () => import("./views/anchor/AnchorRegister"),
  loading: Loading,
});

const maps = Loadable({
  loader: () => import("./views/rtls/Maps"),
  loading: Loading,
});

const mapsFilter = Loadable({
  loader: () => import("./views/rtls/MpaFilter"),
  loading: Loading,
});

const areasFilter = Loadable({
  loader: () => import("./views/Areas/AreasFilter"),
  loading: Loading,
});

const areas = Loadable({
  loader: () => import("./views/Areas/Areas"),
  loading: Loading,
});

const heatMapFilter = Loadable({
  loader: () => import("./views/HeatMap/HeatMapFilter"),
  loading: Loading,
});

const heatMap = Loadable({
  loader: () => import("./views/HeatMap/HeatMap"),
  loading: Loading,
});

const trackingFilter = Loadable({
  loader: () => import("./views/Tracking/TrackingFilter"),
  loading: Loading,
});

const tracking = Loadable({
  loader: () => import("./views/Tracking/Tracking"),
  loading: Loading,
});

const tracingFilter = Loadable({
  loader: () => import("./views/Tracing/TracingFilter"),
  loading: Loading,
});

const tracing = Loadable({
  loader: () => import("./views/Tracing/Tracing"),
  loading: Loading,
});

const anchorInfo = Loadable({
  loader: () => import("./views/anchorStatus/AnchorStatusFilter"),
  loading: Loading,
});

const anchorStatus = Loadable({
  loader: () => import("./views/anchorStatus/AnchorStatus"),
  loading: Loading,
});

const anchorUpdate = Loadable({
  loader: () => import("./views/anchorUpdate/AnchorUpdate"),
  loading: Loading,
});

const reports = Loadable({
  loader: () => import("./views/reports/index"),
  loading: Loading,
});

const locaServer = Loadable({
  loader: () => import("./views/LocalServer/LocalServer"),
  loading: Loading,
});

const alertEvent = Loadable({
  loader: () => import("./views/AlertEvent/list"),
  loading: Loading,
});

const dashboardAlerts = Loadable({
  loader: () => import("./views/NotifyNr15/list"),
  loading: Loading,
});

const MotionDetectionScreen = Loadable({
  loader: () => import("./views/MotionDetection"),
  loading: Loading,
});

const PresenceDetectionScreen = Loadable({
  loader: () => import("./views/PresenceDetection"),
  loading: Loading,
});

// https://github.com/ReactTraining/react-router/tree/master/packages/react-router-config
const routes = [
  { path: "/dashboard", name: "Home", component: DefaultLayout, exact: true },
  { path: "/dashboard", name: "Dashboard", component: Dashboard },
  { path: "/usuarios", exact: true, name: "Users", component: User },
  {
    path: "/usuarios/cadastro",
    exact: true,
    name: "User Details",
    component: UserRegister,
  },
  {
    path: "/usuarios/alterarSenha",
    exact: true,
    name: "Change password",
    component: ChangePassword,
  },
  {
    path: "/usuarios/alterarDados",
    exact: true,
    name: "Change datas",
    component: ChangeDatas,
  },
  {
    path: "/pessoas",
    name: "Pessoas",
    component: workers_relatorio,
    exact: true,
  },
  {
    path: "/pessoas/relatorio",
    exact: true,
    name: "Relatório",
    component: workers_relatorio,
  },
  {
    path: "/pessoas/cadastro",
    exact: true,
    name: "Cadastro",
    component: workers_cadastro,
  },
  {
    path: "/pessoas/informacoes",
    exact: true,
    name: "Informações",
    component: workers_detalhes,
  },

  {
    path: "/clientes",
    name: "Holding",
    component: clientesRelatorio,
    exact: true,
  },
  {
    path: "/clientes/relatorio",
    exact: true,
    name: "Relatório",
    component: clientesRelatorio,
  },
  {
    path: "/clientes/cadastro",
    exact: true,
    name: "Cadastro",
    component: clientesCadastro,
  },

  {
    path: "/empresas",
    name: "Empresas",
    component: companies_relatorio,
    exact: true,
  },
  {
    path: "/empresas/relatorio",
    exact: true,
    name: "Relatório",
    component: companies_relatorio,
  },
  {
    path: "/empresas/cadastro",
    exact: true,
    name: "Cadastro",
    component: companies_cadastro,
  },
  {
    path: "/contratadas",
    name: "Contratadas",
    component: contracted_relatorio,
    exact: true,
  },
  {
    path: "/contratadas/relatorio",
    exact: true,
    name: "Relatório",
    component: contracted_relatorio,
  },
  {
    path: "/contratadas/cadastro",
    exact: true,
    name: "Cadastro",
    component: contracted_cadastro,
  },
  {
    path: "/subcontratadas",
    name: "Subcontratadas",
    component: subcontracted_relatorio,
    exact: true,
  },
  {
    path: "/subcontratadas/relatorio",
    exact: true,
    name: "Cadastro",
    component: subcontracted_relatorio,
  },
  {
    path: "/subcontratadas/cadastro",
    exact: true,
    name: "Cadastro",
    component: subcontracted_cadastro,
  },
  {
    path: "/supervisoes",
    name: "Supervisões",
    component: supervisions_relatorio,
    exact: true,
  },
  {
    path: "/supervisoes/informacoes",
    exact: true,
    name: "Informações",
    component: supervisions_detalhes,
  },
  {
    path: "/empreendimentos",
    name: "Empreendimentos",
    component: plants_relatorio,
    exact: true,
  },
  {
    path: "/empreendimentos/relatorio",
    name: "Relatório",
    component: plants_relatorio,
  },
  {
    path: "/empreendimentos/cadastro",
    name: "Cadastro",
    component: plants_cadastro,
  },
  // { path: "/documentos", name: "Documentos", component: documents_relatorio },
  // {
  //   path: "/documentosAtivos",
  //   name: "Documentos Ativos",
  //   component: documentsActives,
  // },
  { path: "/relatorio", name: "Relatório", component: relatorio },
  { path: "/jobs", exact: true, name: "Relatório", component: jobs },
  {
    path: "/jobs/cadastro",
    exact: true,
    name: "Cadastro",
    component: jobs_cadastro,
  },

  {
    path: "/tipoAtivo",
    exact: true,
    name: "Tipo de Ativo",
    component: actives,
  },
  {
    path: "/tipoAtivo/cadastro",
    exact: true,
    name: "Cadastro",
    component: activesCadastro,
  },

  { path: "/ativos", name: "Ativos", component: ativosRelatorio, exact: true },
  {
    path: "/ativos/relatorio",
    exact: true,
    name: "Relatório",
    component: ativosRelatorio,
  },
  {
    path: "/ativos/cadastro",
    exact: true,
    name: "Cadastro",
    component: ativosCadastro,
  },
  {
    path: "/ativos/informacoes",
    exact: true,
    name: "Informações",
    component: ativosDetalhes,
  },

  {
    path: "/uo",
    exact: true,
    name: "Relatório",
    component: unidade_operacional,
  },
  {
    path: "/uo/cadastro",
    exact: true,
    name: "Cadastro",
    component: unidade_operacional_cadastro,
  },

  { path: "/plant/list", exact: true, name: "Plantas", component: plant },
  {
    path: "/plant/register",
    exact: true,
    name: "Plantas",
    component: plantRegister,
  },

  { path: "/anchor", exact: true, name: "Âncoras", component: anchorList },
  {
    path: "/anchor/register",
    exact: true,
    name: "Registro de Âncoras",
    component: anchorRegister,
  },

  { path: "/maps", exact: true, name: "RTLS", component: maps },
  {
    path: "/maps/filter",
    exact: true,
    name: "MPA filtro",
    component: mapsFilter,
  },
  {
    path: "/areas/filter",
    exact: true,
    name: "Area filter",
    component: areasFilter,
  },
  { path: "/areas", exact: true, name: "MPA areas", component: areas },
  {
    path: "/anchorStatus/filter",
    exact: true,
    name: "Ânchor status",
    component: anchorInfo,
  },

  {
    path: "/anchorStatus",
    exact: true,
    name: "Ânchor status",
    component: anchorStatus,
  },

  {
    path: "/anchorUpdate",
    exact: true,
    name: "Ânchor update",
    component: anchorUpdate,
  },

  {
    path: "/heatMap/filter",
    exact: true,
    name: "MPA heat map",
    component: heatMapFilter,
  },
  { path: "/heatMap", exact: true, name: "MPA heat map", component: heatMap },

  {
    path: "/tracking/filter",
    exact: true,
    name: "MPA tracking",
    component: trackingFilter,
  },
  { path: "/tracking", exact: true, name: "MPA tracking", component: tracking },

  {
    path: "/tracing/filter",
    exact: true,
    name: "MPA tracing",
    component: tracingFilter,
  },
  { path: "/tracing", exact: true, name: "MPA tracing", component: tracing },

  { path: "/reports", exact: true, name: "Relatórios", component: reports },
  {
    path: "/localServer",
    exact: true,
    name: "Servidor Local",
    component: locaServer,
  },
  {
    path: "/alertEvent/justify",
    exact: true,
    name: "Justificativas",
    component: alertEvent,
  },
  {
    path: "/dashboardAlert",
    exact: true,
    name: "Alertas de Ciclos",
    component: dashboardAlerts,
  },
  {
    path: "/motionDetection",
    exact: true,
    name: "Detecção de Movimentos",
    component: MotionDetectionScreen,
  },
  {
    path: "/presence-detection",
    exact: true,
    name: "Detecção de Presença",
    component: PresenceDetectionScreen,
  },
];

export default routes;
