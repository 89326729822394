import React, { Component } from 'react';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import logo from '../../../assets/img/brand/MPA-completa_2.png';
import { DEFAULT_COLOR } from '../../../util/util';

const optionsToast = { position: "top-center", autoClose: 3000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, };

class Reset extends Component {

  constructor(props) {

    super(props);

    this.reset = this.reset.bind(this);
    this.validateForce = this.validateForce.bind(this);

    this.state = {
      worker: null,
      passwd: '',
      confirm_passwd: '',
      encrypt_pwd: '',
      userName: '',
      showButton: true,
      token: '',
      name: '',
      color: DEFAULT_COLOR,
      logo: require(`../../../assets/img/brand/khomp.svg`),
    };
  }

  componentDidMount() {
    let params = new URLSearchParams(this.props.location.search)
    this.setState({ encrypt_pwd: params.get('param') })
    this.validateToken(params.get('param'));
  }

  async validateToken(token) {
    const url = process.env.REACT_APP_URI + '/validations/verifyTokenUser';
    const resp = await fetch(url, {
      method: 'POST',
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ token })
    });
    if (resp.status === 200) {
      const res = await resp.json();
      this.setState({
        token: res.token,
        name: res.name,
      });
      if (res.color) {
        this.setState({
          color: res.color,
          logo: require(`../../../assets/img/brand/${res.logo}`),
        })
      }
    } else {
      this.setState({ showButton: false });
      toast.error(<div> <h4>{resp.msg}<br /> </h4>{'Solicite nova alteração de senha'}</div>, optionsToast);
    }
  }

  /**
     * Verifica se senha porrui
     * Tamanho: 8
     * Ao menos 3 dos:
     * Letra: Maiuscula, Minuscula
     * Numero: Ao menos um
     * Caracter: Ao menos um
     * @param {*} password
     */
  async validateForce(password) {
    let letraMaiuscula = /^(?=.*?[A-Z])/;
    let letraMinuscula = /^(?=.*?[a-z])/;
    let numero = /^(?=.*?[0-9])/;
    let caracter = /^(?=.*?[#?!@$%^&*-])/;
    let tamanho = password.length;
    let msg = [];

    if (this.state.passwd === '') {
      msg.push('Digite a nova senha ');
    }
    if (this.state.confirm_passwd === '') {
      msg.push('Confirme a sua senha ');
    }
    if (this.state.passwd !== this.state.confirm_passwd) {
      msg.push('Senhas não conferem');
    }
    const toastMsg = msg.map((txt) => {
      return <span>{txt}<br /></span>
    });
    if (msg.length > 0) {
      toast.error(<div> <h4>{'ERRO PASSWORD'}<br /> </h4>
        {toastMsg}</div>,
        optionsToast);
      setTimeout(() => {
        this.setState({ showAlert: false });
      }, 5000);
    } else {

      if (tamanho < 8) {
        toast.error(<div style={{ textAlign: 'center' }}>
          <h4>{'ERRO PASSWORD'}<br /> </h4>
          {'A senha deve ter oito ou mais caracteres.'}</div>,
          optionsToast);
        setTimeout(() => {
          this.setState({ showAlert: false });
        }, 5000);
        return;
      }

      if (!letraMaiuscula.test(password)) {
        msg.push(' Digite a senha com uma letra maiuscula. ');
      }
      if (!letraMinuscula.test(password)) {
        msg.push(' Digite a senha com uma letra minuscula. ');
      }
      if (!numero.test(password)) {
        msg.push(' Digite a senha com um numero. ');
      }
      if (!caracter.test(password)) {
        msg.push(' Digite a senha com um caracter especial. ');
      }


      if (msg.length > 1) {
        const toastMsg = msg.map((txt) => {
          return <span>{txt}<br /></span>
        })
        toast.error(<div style={{ textAlign: 'center' }}>
          <h4>{'ERRO PASSWORD'}<br /> </h4>
          {toastMsg}</div>,
          optionsToast);
        setTimeout(() => {
          this.setState({ showAlert: false });
        }, 5000);
      } else {
        await this.reset(this.state.passwd);
      }
    }

  }

  async reset(senha) {
    if (this.state.passwd !== this.state.confirm_passwd || this.state.passwd === '' || this.state.confirm_passwd === '') {
      toast.error(<div> <h4>{'PASSWORD AND CONFIRM PASSWORD NOT MATCH'}<br /> </h4>{'SENHAS E CONFIRMAÇÂO NÃO COINCIDEM'}</div>, optionsToast);
      return;
    }
    const formData = {
      password: senha,
    };
    const url = process.env.REACT_APP_URI + '/validations/resetPassword';

    const resp = await fetch(url, {
      method: 'POST',
      headers: {
        "Content-Type": "application/json",
        Authorization: 'Bearer ' + this.state.token,
      },
      body: JSON.stringify(formData)
    });

    const response = await resp.json();
    if (resp.status === 200) {
      toast.success(<div> <h4>{'RESET PASSWORD SUCCESS'}<br /> </h4></div>, { onClose: this.props.history.push("/login") }, optionsToast);

    } else {
      toast.error(<div> <h4>{'RESET PASSWORD FAILURE'}<br /> </h4>{response.msg}</div>, optionsToast);
    }
  }

  render() {
    return (
      <div className="app flex-row align-items-center" style={{ backgroundColor: this.state.color }}>
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable={false}
          pauseOnHover
        />
        <Container>
          <Row className="justify-content-center" >
            <img src={logo} alt='RTLS' style={{ width: '40%', height: '40%' }} mode='fit' />
          </Row>
          <Row className="justify-content-center" style={{ marginTop: '0em' }}>
            <Col md="8">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form>
                      <h1>Reset de Senha - {this.state.name}</h1>
                      <p className="text-muted"></p>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="password" placeholder="Password" autoComplete="current-password" onFocus={() => this.setState({ passwd: '' })} value={this.state.passwd} onChange={(senha) => this.setState({ passwd: senha.target.value })} />
                      </InputGroup>
                      <InputGroup className="mb-4">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="icon-lock"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input type="password" placeholder="Confirmar Password" autoComplete="current-password" onFocus={() => this.setState({ confirm_passwd: '' })} value={this.state.confirm_passwd} onChange={(senha) => this.setState({ confirm_passwd: senha.target.value })} onKeyPress={(event) => {
                          if (event.key === "Enter") {
                            console.log(event.key)
                            this.reset(this.state.passwd)
                          }
                        }} />
                      </InputGroup>
                      <Row>
                        <Col xs="6">
                          {this.state.showButton &&
                            <Button color="primary" style={{backgroundColor: this.state.color}} className="px-4" onClick={this.validateForce.bind(this, this.state.passwd)}>Enviar</Button>
                          }
                        </Col>
                        <Col xs="6" className="text-right">
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <img src={this.state.logo} alt='logo' mode='fit' style={{ width: '15%', height: '15%' }} />
          </Row>
        </Container>
      </div>
    );
  }
}

export default Reset;
