import React, { Component } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Container } from 'reactstrap';

import {
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarNav,
} from '@coreui/react';
// sidebar nav config
import navigation from '../../util/navigations';
// routes config
import routes from '../../routes';

import DefaultFooter from './DefaultFooter';
import DefaultHeader from './DefaultHeader';

import IdleTimer from 'react-idle-timer';
import session from '../../util/session';
import profiles from '../../util/profiles';
import encryptUtil from '../../util/encryptUtil';
import { getColorSystem } from '../../util/util';

// let time = 0;

class DefaultLayout extends Component {

  componentDidMount() {

    const showMenu = (!profiles.isProfileContracted(session.getUserProfile()) &&
      !profiles.isProfileSubContracted(session.getUserProfile()) &&
      session.getUserProfile() !== profiles.PROFILE_WORKER);

    this.setState({
      authed: session.isSessionTokenValid(),
      last: new Date().getTime(),
      showMenu,
      menus: navigation()
    });
    // time = new Date().getTime();
    // this.check();
    this.validateToken();

    try {
      let userConfigs = sessionStorage.getItem('userConfig');
      if (userConfigs) {
        userConfigs = JSON.parse(encryptUtil.decrypt(userConfigs));
        this.setState({
          worker_id: userConfigs.user,
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  componentDidUpdate() {
    session.createSessionToken();
  }

  constructor() {
    super();

    this.signOut = this.signOut.bind(this)
    // this.check = this.check.bind(this);
    this.idleTimer = null

    this.state = {
      authed: true,
      worker_id: '',
      last: '',
      showMenu: true,
      menus: { items: [] }
    };
  };

  loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>

  signOut() {
    sessionStorage.clear();
    this.props.history.push('/login')
  }

  // check() {
  //   setInterval(() => {
  //     let current = (new Date()).getTime();
  //     if (current - time > 30000) {
  //       this.signOut();
  //     }
  //     time = current;
  //   }, 1000);
  // }

  validateToken() {
    setInterval(() => {
      if (!session.isSessionTokenValid()) {
        this.signOut();
      }
    }, 10000);
  }

  render() {
    return (
      <div className="app">
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          // onActive={() => console.log('time remaining', this.idleTimer.getRemainingTime())}
          onIdle={this.signOut}
          debounce={250}
          crossTab={true}
          timeout={1000 * 60 * 60} />
        <AppHeader fixed style={{ backgroundColor: getColorSystem() }}>
          <DefaultHeader />
        </AppHeader>
        <div className="app-body">

          {/* Controle por perfil, para a exibição do menu lateral */}
          {this.state.showMenu &&
            <AppSidebar fixed display="lg">
              <AppSidebarNav navConfig={this.state.menus} {...this.props} />
            </AppSidebar>
          }
          <main className="main" style={this.state.showMenu ? { backgroundColor: 'white' } : { backgroundColor: 'white', marginLeft: '0px' }} >
            <Container fluid style={{ marginTop: '2em' }} >
              <Switch>
                {routes.map((route, idx) => {
                  return route.component ? (
                    <Route
                      key={idx}
                      path={route.path}
                      exact={route.exact}
                      name={route.name}
                      render={props => (
                        this.state.authed === true ? <route.component {...props} /> : <Redirect from="/" to="/login" />
                      )} />
                  ) : (null);
                }
                )}
                <Redirect from="/" to="/login" />
              </Switch>
            </Container>
          </main>

        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
    );
  }
}

export default DefaultLayout;
