import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import CacheBuster from './CacheBuster';

// Containers
import { DefaultLayout } from './containers';
// Pages
import { Login, Reset, Page404, Page500, Register } from './views/Pages';
import WorkerPassword from './views/Pessoas/password/workerPassword';
import UserPassword from './views/Users/userPassword';
import NotifyAlertNr15 from './views/NotifyNr15/list';

import intl from 'react-intl-universal';

import './App.scss';

const locales = {
  'pt-BR': require('./locales/pt-BR.json'),
  'en-US': require('./locales/en-US.json')
}

class App extends Component {

  constructor() {
    super();
    const navigatorLocale = locales[navigator.language] ? navigator.language : 'pt-BR'
    const locale = localStorage.getItem('language') ? localStorage.getItem('language') : navigatorLocale;

    const currentLocale = locale;
    intl.init({
      currentLocale,
      locales
    });

  }

  render() {
    return (
      <CacheBuster>
        {({ loading, isLatestVersion, refreshCacheAndReload }) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            refreshCacheAndReload();
          }

          return (
            <HashRouter>
              <Switch>
                <Route exact path="/login" name="Login Page" component={Login} />
                <Route exact path="/login/:client" name="Login Page Custom" component={Login} />
                <Route exact path="/workerPassword" name="Password" component={WorkerPassword} />
                <Route exact path="/userPassword" name="User Password" component={UserPassword} />
                <Route exact path="/reset_pwd" name="Reset Password Page" component={Reset} />
                <Route exact path="/register" name="Register Page" component={Register} />
                <Route exact path="/dashboard_alerts" name="AlertCycle" component={NotifyAlertNr15} />
                <Route exact path="/404" name="Page 404" component={Page404} />
                <Route exact path="/500" name="Page 500" component={Page500} />
                <Route path="/" name="Home" component={DefaultLayout} />
              </Switch>
            </HashRouter>
          );
        }}
      </CacheBuster>
    );
  }
}

export default App;
