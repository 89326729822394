import React from "react";
import { Card, CardBody, CardHeader } from "reactstrap";

export default function ToastAlert({ names }) {
  return (
    <Card style={{ backgroundColor: "transparent", border: "none" }}>
      <CardHeader style={{ backgroundColor: "transparent", border: "none" }}>
        <span style={{ color: "#f30000", fontSize: "30px", fontWeight: "bold" }}>
          Novos Alertas!
        </span>
      </CardHeader>
      <CardBody>
        {names.map((name) => (
          <div>
            <span
              style={{ color: "#000", fontSize: "25px", fontWeight: "bold" }}
            >
              {name}
            </span>
          </div>
        ))}
      </CardBody>
    </Card>
  );
}
