import React, { Component } from 'react';
import {
  Container, Card, CardBody, Form, Row, Col, FormGroup,
  Label, Input, FormFeedback, Button,
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
  AppFooter,
  AppHeader,
} from '@coreui/react';

import DefaultFooter from '../../../containers/DefaultLayout/DefaultFooter';
import DefaultHeader from '../../../containers/DefaultLayout/DefaultHeader';
import profiles from '../../../util/profiles';
import util from '../../../util/util';

class WorkerPassword extends Component {

  constructor() {
    super();

    this.save = this.save.bind(this);

    this.state = {
      worker: '',
      showForm: false,
      showError: false,
      textError: '',
      password: '',
      confirmPassword: '',
      showButton: true,
    }
  };

  componentDidMount() {
    const query = this.props.location.search.slice(1);
    let partes = query.split('&');
    let data = {};
    partes.forEach(function (parte) {
      let chaveValor = parte.split('=');
      let chave = chaveValor[0];
      let valor = chaveValor[1];
      data[chave] = valor;
    });
    this.verfiyTokenValid(data);
  }

  async verfiyTokenValid(data) {
    if (data && data.id) {
      const url = process.env.REACT_APP_URI + '/validations/verifyToken';
      const resp = await fetch(url).then(async (response) => await response.json());
      if (resp) {
        this.setState({
          showForm: true,
          worker: resp.worker
        })
      } else {
        this.setState({
          showError: true,
          textError: 'Não foi possível validar o link!'
        })
      }
    } else {
      this.setState({
        showError: true,
        textError: 'Dados inválidos! Solicite ao administrador um novo link para o cadastro da senha.'
      })
    }
  }

  async save() {
    if (this.state.password === '' || this.state.confirmPassword === '' ||
      (this.state.password !== this.state.confirmPassword)) {

      toast.error(<div> <h4>{'Dados inválidos'}<br /> </h4></div>, { position: "top-center", autoClose: 3000, hideProgressBar: true, closeOnClick: true, pauseOnHover: true, draggable: false, });
    } else {
      const accessLevel = this.state.worker.supervisor_permission ? profiles.PROFILE_SUPERVISOR : profiles.PROFILE_WORKER;

      let formData = {
        workerId: this.state.worker._id,
        username: this.state.worker.email,
        password: this.state.password,
        accessLevel,
        name: this.state.worker.name,
        companieId: this.state.worker.companie._id,
        clientId: this.state.worker.companie.clientId._id,
        cpf: this.state.worker.cpf,
        status: util.STATUS_LOGIN_ACTIVE,
      }
      if (this.state.worker.contracted) {
        formData.contractedId = this.state.worker.contracted._id;
      }
      if (this.state.worker.subContracted) {
        formData.subContractedId = this.state.worker.subContracted._id;
      }

      const url = process.env.REACT_APP_URI + '/validations/savePassword';
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          "Content-Type": "application/json", 'Accept': '*/*', "Connection": "keep-alive",
          "Accept-Encoding": "gzip, deflate"
        },
        body: JSON.stringify(formData)
      });
      const res = await response.json();
      this.setState({ showRedirect: true });
      console.log(response.status, res);

      if (response.status === 408) {
        this.setState({
          showError: true,
          textError: `O e-mail do usuário (${this.state.worker.email}) já está cadastrado na base de dados,
          não foi possível cadastrar a senha. Solicite para o administrador a alteração do e-mail do seu cadastro.`,
        });
        return;
      }

      if (response.status === 400) {
        this.setState({
          showError: true,
          textError: res.msg,
        });
        return;
      }

      if (response.status !== 200) {
        this.setState({
          showError: true,
          textError: 'Ocorreu um erro no cadastro da senha do usuário',
        });
      } else {
        if (res.status === 'SUCCESS') {
          this.setState({
            showButton: false,
            showError: true,
            textError: 'Senha cadastrada com sucesso!',
          });
          setTimeout(() => {
            this.props.history.push({ pathname: '/' });
          }, 5000)
        } else {
          this.setState({
            showError: true,
            textError: 'Não foi possível cadastrar a senha!',
          });
        }
      }
    }
  }

  loading = () => <div className="animated fadeIn pt-1 text-center">Loading...</div>

  render() {
    return (
      <div className="app">
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable={false}
          pauseOnHover
        />
        <AppHeader fixed style={{ backgroundColor: "#0074D8" }}>
          <DefaultHeader />
        </AppHeader>
        <div className="app-body">

          <main className="main" style={{ backgroundColor: 'white' }} >
            {/* <AppBreadcrumb appRoutes={routes}/> */}
            <Container fluid style={{ marginTop: '2em' }} >
              {this.state.showError &&
                <Card>
                  <CardBody style={{ height: '80px', textAlign: 'center', color: 'red', fontSize: '20px' }}>
                    {this.state.textError}
                  </CardBody>
                </Card>
              }
              {this.state.showForm &&
                <Card>
                  <span style={{ fontSize: '20px', color: '#0074d8', marginLeft: '20px' }}>Cadastro de {this.state.worker.supervisor_permission ? 'supervisor' : 'profissional'}</span>
                  <CardBody>
                    <h5 style={{ fontWeight: 'bold', color: '#0e457b' }} >Cadastro de senha - {this.state.worker.name}</h5>
                    <hr />
                    <Form>
                      <Row>
                        <Col>
                          <FormGroup>
                            <Label htmlFor="password" style={{ fontWeight: 'bold' }}>Senha</Label>
                            <Input
                              valid={this.state.password.length > 3}
                              invalid={this.state.password.length < 4}
                              type="password"
                              id="password"
                              name="password"
                              autoComplete="off"
                              placeholder="Digite a senha"
                              onChange={(option) => this.setState({ password: option.target.value })} />
                            <FormFeedback className="help-block">Senha deve ter no minimo 4 caracteres</FormFeedback>
                          </FormGroup>
                        </Col>
                        <Col>
                          <FormGroup>
                            <Label htmlFor="confirmPassword" style={{ fontWeight: 'bold' }}>Confirmar Senha</Label>
                            <Input
                              valid={this.state.password === this.state.confirmPassword}
                              invalid={this.state.password !== this.state.confirmPassword}
                              type="password"
                              id="confirmPassword"
                              name="confirmPassword"
                              autoComplete="off"
                              placeholder="Confirme sua senha"
                              onChange={(option) => this.setState({ confirmPassword: option.target.value })} />
                            <FormFeedback className="help-block">Senhas não coincidem</FormFeedback>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row style={{ height: '50px' }}>
                        <Col sm={{ size: 'auto', offset: 0 }}>
                          {this.state.showButton && <Button
                            size="xl"
                            color="primary"
                            onClick={() => this.save()} style={util.getStyleButton({ marginRight: '50px' })}>Concluir cadastro
                            </Button>}
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              }
            </Container>
          </main>
        </div>
        <AppFooter>
          <DefaultFooter />
        </AppFooter>
      </div>
    );
  }
}

export default WorkerPassword;
