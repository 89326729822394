const profiles = require("./profiles");
const session = require("./session");

/**
 * Retorna a lista de items de menu com base no perfil do usuario logado
 */
function getItensByProfile() {
  const profile = session.getUserProfile();

  const retItems = {
    items: false,
  };

  const userConfig = session.getUserConfigs();
  if (!userConfig) {
    return [];
  }
  const { 
    areas,
    motionDetection,
    presenceDetection,
    enablersVisualConfig
  } = userConfig;

  if (profile === profiles.PROFILE_SUPERUSER) {
    retItems.items = itemsAdmin;
  }

  //client - holding
  else if (profile === profiles.PROFILE_CLIENT) {
    retItems.items = itemsClientAdmin;
  } else if (profile === profiles.PROFILE_CLIENT_VIEWER) {
    retItems.items = itemsClient;
  } else if (
    profile === profiles.PROFILE_CLIENT_WORKER_ADMIN ||
    profile === profiles.PROFILE_CLIENT_WORKER_VIEWER
  ) {
    retItems.items = itemsClientWorker;
  } else if (profile === profiles.PROFILE_CLIENT_ACTIVE_ADMIN) {
    retItems.items = itemsClientActive;
  } else if (profile === profiles.PROFILE_CLIENT_ACTIVE_VIEWER) {
    retItems.items = itemsClientActive;
  }

  //companie
  else if (profile === profiles.PROFILE_COMPANIE) {
    retItems.items = itemsCompanieAdmin;
  } else if (profile === profiles.PROFILE_COMPANIE_VIEWER) {
    retItems.items = itemsCompanie;
  } else if (
    profile === profiles.PROFILE_COMPANIE_WORKER_ADMIN ||
    profile === profiles.PROFILE_COMPANIE_WORKER_VIEWER
  ) {
    retItems.items = itemsCompanieWorker;
  } else if (
    profile === profiles.PROFILE_COMPANIE_ACTIVE_ADMIN ||
    profile === profiles.PROFILE_COMPANIE_ACTIVE_VIEWER
  ) {
    retItems.items = itemsCompanieActive;
  }

  //supervisor
  else if (profile === profiles.PROFILE_SUPERVISOR) {
    retItems.items = itemsSupervisor;
  }

  if (retItems.items && retItems.items.length) {
    if (!areas) {
      retItems.items = retItems.items.filter(
        (option) =>
          option.name !== area.name &&
          option.name !== justify.name &&
          option.name !== dashboardAlerts.name
      );
    }

    if (!motionDetection) {
      retItems.items = retItems.items.filter(
        (option) => option.name !== motionDetectionData.name
      );
    }

    if (!presenceDetection) {
      retItems.items = retItems.items.filter(
        (option) => option.name !== presenceDetectionData.name
      );
    }

    if (enablersVisualConfig) {
      const filterItems = [
        workers.name,
        contracted.name,
        job.name,
      ]
      retItems.items = retItems.items.filter(
        (option) => !filterItems.includes(option.name)
      );
    }
  }

  return retItems;
}

const dashboard = {
  name: "Dashboard",
  url: "/dashboard",
  icon: "cui-dashboard",
};

const workers = {
  name: "Profissionais",
  url: "/pessoas/relatorio",
  icon: "cui-people",
};

const holding = {
  name: "Holding",
  url: "/clientes/relatorio",
  icon: "fa fa-building",
};

const companies = {
  name: "Empreendimentos",
  url: "/empresas/relatorio",
  icon: "cui-briefcase",
};

const companiesRegister = {
  name: "Empreendimento",
  url: "/empresas/cadastro",
  icon: "cui-briefcase",
};

const contracted = {
  name: "Contratadas",
  url: "/contratadas/relatorio",
  icon: "cui-briefcase",
};

const operationalUnit = {
  name: "Unidade Operacional",
  url: "/uo",
  icon: "fa fa-bank",
};

const plant = {
  name: "Plantas",
  url: "/plant/list",
  icon: "fa fa-building-o",
};

const job = {
  name: "Cargos",
  url: "/jobs",
  icon: "fa fa-sitemap",
};

const active = {
  name: "Ativos",
  url: "/ativos",
  icon: "fa fa-cog",
};

const typeActive = {
  name: "Tipo de Ativo",
  url: "/tipoAtivo",
  icon: "fa fa-cogs",
};

const user = {
  name: "Usuários",
  url: "/usuarios",
  icon: "fa fa-user",
};

const anchor = {
  name: "Ancoras",
  url: "/anchor",
  icon: "fa fa-signal",
};

const anchorUpdate = {
  name: "Atualização Âncoras",
  url: "/anchorUpdate",
  icon: "fa fa-upload",
};

const rtls = {
  name: "MPA",
  url: "/maps/filter",
  icon: "fa fa-globe",
};

const rtlsStatus = {
  name: "MPA - status",
  url: "/anchorStatus/filter",
  icon: "fa fa-history",
};

const area = {
  name: "Áreas",
  url: "/areas/filter",
  icon: "fa fa-caret-square-o-down",
};

const motionDetectionData = {
  name: "Detecção Movimento",
  url: "/motionDetection",
  icon: "fa fa-exchange",
};

const presenceDetectionData = {
  name: "Detecção Presença",
  url: "/presence-detection",
  icon: "fa fa-exchange",
};

const heatMap = {
  name: "Mapa de calor",
  url: "/heatMap/filter",
  icon: "fa fa-fire",
};

const tracking = {
  name: "Hist. Posições",
  url: "/tracking/filter",
  icon: "fa fa-map",
};
const tracing = {
  name: "Rastreamento",
  url: "/tracing/filter",
  icon: "fa fa-map-pin",
};

const reports = {
  name: "Relatórios",
  url: "/reports",
  icon: "cui-inbox",
};

const loclServer = {
  name: "Servidor Local",
  url: "/localServer",
  icon: "fa fa-server",
};

const justify = {
  name: "Justificativas",
  url: "/alertEvent/justify",
  icon: "fa fa-edit",
};

const dashboardAlerts = {
  name: "Alertas Ciclos",
  url: "/dashboardAlert",
  icon: "fa fa-warning",
};

// const backup = {
//   name: 'Backup DB',
//   url: '/bkp_db',
//   icon: 'fa fa-database'
// };

const itemsAdmin = [
  dashboard,
  workers,
  holding,
  companies,
  contracted,
  operationalUnit,
  plant,
  anchor,
  job,
  active,
  typeActive,
  rtls,
  rtlsStatus,
  area,
  heatMap,
  tracking,
  tracing,
  reports,
  loclServer,
  anchorUpdate,
  justify,
  dashboardAlerts,
  motionDetectionData,
  presenceDetectionData,
  {
    divider: true,
    class: "m-2",
  },
  user,
  {
    divider: true,
    class: "m-2",
  },
];

const itemsClient = [
  dashboard,
  workers,
  companies,
  contracted,
  operationalUnit,
  plant,
  anchor,
  job,
  active,
  typeActive,
  rtls,
  rtlsStatus,
  area,
  heatMap,
  tracking,
  tracing,
  reports,
  loclServer,
  anchorUpdate,
  justify,
  dashboardAlerts,
  motionDetectionData,
  presenceDetectionData,
  {
    divider: true,
    class: "m-2",
  },
];

const itemsClientAdmin = [
  dashboard,
  workers,
  companies,
  contracted,
  operationalUnit,
  plant,
  anchor,
  job,
  active,
  typeActive,
  rtls,
  area,
  rtlsStatus,
  heatMap,
  tracking,
  tracing,
  reports,
  loclServer,
  anchorUpdate,
  justify,
  dashboardAlerts,
  motionDetectionData,
  presenceDetectionData,
  {
    divider: true,
    class: "m-2",
  },
  user,
];

const itemsClientWorker = [
  dashboard,
  workers,
  companies,
  contracted,
  operationalUnit,
  plant,
  anchor,
  job,
  rtls,
  area,
  heatMap,
  tracking,
  tracing,
  reports,
  motionDetectionData,
  presenceDetectionData,
  {
    divider: true,
    class: "m-2",
  },
];

const itemsClientActive = [
  dashboard,
  companies,
  contracted,
  operationalUnit,
  plant,
  anchor,
  active,
  typeActive,
  rtls,
  area,
  heatMap,
  tracking,
  tracing,
  reports,
  motionDetectionData,
  presenceDetectionData,
  {
    divider: true,
    class: "m-2",
  },
];

const itemsCompanie = [
  dashboard,
  workers,
  companiesRegister,
  contracted,
  operationalUnit,
  plant,
  anchor,
  job,
  active,
  typeActive,
  rtls,
  area,
  rtlsStatus,
  heatMap,
  tracking,
  tracing,
  reports,
  loclServer,
  anchorUpdate,
  justify,
  dashboardAlerts,
  motionDetectionData,
  presenceDetectionData,
  {
    divider: true,
    class: "m-2",
  },
];

const itemsCompanieAdmin = [
  dashboard,
  workers,
  companiesRegister,
  contracted,
  operationalUnit,
  plant,
  anchor,
  job,
  active,
  typeActive,
  rtls,
  area,
  rtlsStatus,
  heatMap,
  tracking,
  tracing,
  reports,
  loclServer,
  anchorUpdate,
  justify,
  dashboardAlerts,
  motionDetectionData,
  presenceDetectionData,
  {
    divider: true,
    class: "m-2",
  },
  user,
];

const itemsCompanieWorker = [
  dashboard,
  workers,
  companiesRegister,
  contracted,
  operationalUnit,
  plant,
  job,
  rtls,
  area,
  heatMap,
  tracking,
  tracing,
  reports,
  motionDetectionData,
  presenceDetectionData,
  {
    divider: true,
    class: "m-2",
  },
];

const itemsCompanieActive = [
  dashboard,
  companiesRegister,
  contracted,
  operationalUnit,
  plant,
  active,
  typeActive,
  rtls,
  area,
  heatMap,
  tracking,
  tracing,
  reports,
  motionDetectionData,
  presenceDetectionData,
  {
    divider: true,
    class: "m-2",
  },
];

const itemsSupervisor = [
  dashboard,
  workers,
  job,
  active,
  typeActive,
  justify,
  dashboardAlerts,
  {
    divider: true,
    class: "m-2",
  },
];

export default getItensByProfile;
