import React, { Component } from 'react';
import { Nav, NavItem, } from 'reactstrap';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { AppNavbarBrand, AppSidebarToggler } from '@coreui/react';
import DefaultHeaderDropdown from './DefaultHeaderDropdown'
// import logo from '../../assets/img/brand/logo-khomp.png'
import sygnet from '../../assets/img/brand/datalife-idble-logo.svg'

import session from '../../util/session';
import profiles from '../../util/profiles';
import { getColorSystem, getLogo } from '../../util/util';

const propTypes = {
  children: PropTypes.node,
};

const defaultProps = {};

class DefaultHeader extends Component {
  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  constructor(props) {

    super(props);

    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.state = {
      width: 0,
      height: 0
    };
  }

  /**
   * Retorna os links de menu, com base no perfil de acesso do usuário logado
   */
  getMenu() {
    const profile = session.getUserProfile();
    const worker = profiles.getProfileType(profile) !== profiles.PERMISSION_VIEW_ACTIVES;
    const actives = profiles.getProfileType(profile) !== profiles.PERMISSION_VIEW_WORKERS;
    const showSubContracted = !profiles.isProfileSubContracted(profile);
    const users = profiles.isPageValid(profiles.USERS) !== '/login';
    return (
      <>
        {worker &&
          <NavItem className="px-3">
            <Link style={{ color: '#fff' }} to='/pessoas' >Profissionais</Link>
          </NavItem>
        }
        {actives &&
          <NavItem className="px-3">
            <Link style={{ color: '#fff' }} to='/ativos' >Ativos</Link>
          </NavItem>
        }
        {profiles.isProfileContracted(session.getUserProfile()) &&
          <NavItem className="px-3">
            <Link style={{ color: '#fff' }} to={{ pathname: '/contratadas/cadastro', query: { _id: session.getUserConfigs().contracted, edit: true } }}>Dados da Empresa</Link>
          </NavItem>
        }
        {profiles.isProfileSubContracted(session.getUserProfile()) &&
          <NavItem className="px-3">
            <Link style={{ color: '#fff' }} to={{ pathname: '/subcontratadas/cadastro', query: { _id: session.getUserConfigs().subContracted, edit: true } }}>Dados da Empresa</Link>
          </NavItem>
        }
        {showSubContracted &&
          <NavItem className="px-3">
            <Link style={{ color: '#fff' }} to='/subcontratadas'>Sub Contratadas</Link>
          </NavItem>
        }
        {users &&
          <NavItem className="px-3">
            <Link style={{ color: '#fff' }} to='/usuarios'>Usuários</Link>
          </NavItem>
        }
      </>
    )
  }

  updateWindowDimensions() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }

  changeLanguage(language) {
    localStorage.setItem('language', language);
    window.location.reload();
  }

  render() {

    // eslint-disable-next-line
    const { children, ...attributes } = this.props;

    return (
      <React.Fragment>
        <AppSidebarToggler className="d-lg-none" display="md" mobile />
        <AppNavbarBrand style={{ backgroundColor: getColorSystem(), marginLeft: this.state.width > 1024 ? '-2.5em' : '' }}
          full={{ src: getLogo(), height: '100%', alt: 'Logo' }}
          minimized={{ src: sygnet, width: 30, height: 30, alt: 'Khomp' }}
        />{this.state.width > 1024 && <span style={{ fontWeight: 'bold', color: '#fff', fontSize: '2vh', marginTop: '0.3em' }}></span>}
        {(profiles.isProfileContracted(session.getUserProfile()) ||
          profiles.isProfileSubContracted(session.getUserProfile())) &&
          <Nav className="d-md-down-none" navbar style={{ marginLeft: '50px' }}>
            {this.getMenu()}
          </Nav>
        }
        {/* <Button color="success" outline onClick={() => this.changeLanguage('pt-BR')}>PT</Button>
        <Button color="success" outline onClick={() => this.changeLanguage('en-US')} style={{ marginLeft: '1rem' }}>EN</Button> */}
        <Nav className="ml-auto" navbar style={{ paddingRight: '20px' }}>
          <DefaultHeaderDropdown accnt />
        </Nav>
      </React.Fragment>
    );
  }
}

DefaultHeader.propTypes = propTypes;
DefaultHeader.defaultProps = defaultProps;

export default DefaultHeader;
