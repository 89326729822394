const intl = require('react-intl-universal');
const { getUserConfigs } = require('./session');

const STATUS_ACTIVE = 0;
const STATUS_INACTIVE = 1;
const STATUS_REJECTED = 2;
const STATUS_ANALYZE = 3;
const STATUS_DRAFT = 4;

const STATUS_DOC_ACTIVE = 0;
const STATUS_DOC_INACTIVE = 1;
const STATUS_DOC_REJECTED = 2;
const STATUS_DOC_NEW = 3;

const CLASSIFICATION_DOC_NORMAL = 0;
const CLASSIFICATION_DOC_ANALYZE = 1;
const CLASSIFICATION_DOC_PENDING = 2;
const CLASSIFICATION_DOC_EXPIRING = 3;

const TYPE_WORKER = 0;
const TYPE_ACTIVE = 1;

const CLASSIFICATION_WORKER_NORMAL = 0;
const CLASSIFICATION_WORKER_EXPIRING = 1;
const CLASSIFICATION_WORKER_PENDING = 2;

const STATUS_REGISTER_ACTIVE = 0;
const STATUS_REGISTER_INACTIVE = 1;

const STATUS_LOGIN_ACTIVE = 0;
const STATUS_LOGIN_INACTIVE = 1;
const STATUS_LOGIN_EXPIRE = 2;
const STATUS_LOGIN_PASSWORD_EXPIRE = 3;
const STATUS_LOGIN_ERR_PASSWORD = 4;
const STATUS_LOGIN_WAITING_PASS = 5;

const DEFAULT_COLOR = '#3a7243';

const mapStatusUser = new Map();
mapStatusUser.set(STATUS_LOGIN_ACTIVE, 'Ativo');
mapStatusUser.set(STATUS_LOGIN_INACTIVE, 'Inativo');
mapStatusUser.set(STATUS_LOGIN_EXPIRE, 'Expirado por inatividade');
mapStatusUser.set(STATUS_LOGIN_PASSWORD_EXPIRE, 'Senha expirada');
mapStatusUser.set(STATUS_LOGIN_ERR_PASSWORD, 'Bloqueado por erro de senha')
mapStatusUser.set(STATUS_LOGIN_WAITING_PASS, 'Aguardando senha');

const getLabelStatusWorker = function (status) {
  if (status === 0) {
    return 'Habilitado';
  }
  if (status === 1) {
    return 'Inativo';
  }
  if (status === 2) {
    return 'Reprovado';
  }
  if (status === 3) {
    return 'Em análise';
  }
  if (status === 4) {
    return 'Rascunho';
  }
  return '';
}

const getColorStatusWorker = function (status) {
  if (status === 0) {
    return 'green';
  }
  if (status === 1) {
    return 'gray';
  }
  if (status === 2) {
    return 'red';
  }
  if (status === 3) {
    return 'blue';
  }
  if (status === 4) {
    return 'gray';
  }
  return 'black';
}

const getLabelStatusDocument = function (status) {
  if (status === STATUS_DOC_ACTIVE) {
    return 'Ativo';
  }
  if (status === STATUS_DOC_INACTIVE) {
    return 'Inativo';
  }
  if (status === STATUS_DOC_REJECTED) {
    return 'Rejeitado';
  }
  if (status === STATUS_DOC_NEW) {
    return 'Novo cadastro';
  }
}

const getLabelClassificationDocument = (classification) => {
  if (classification === CLASSIFICATION_DOC_ANALYZE) {
    return 'Em análise';
  }
  if (classification === CLASSIFICATION_DOC_EXPIRING) {
    return 'Vencendo';
  }
  if (classification === CLASSIFICATION_DOC_NORMAL) {
    return 'Normal';
  }
  if (classification === CLASSIFICATION_DOC_PENDING) {
    return 'Pendência';
  }
}

const getLabelClassificationWorker = (classification) => {
  if (classification === CLASSIFICATION_WORKER_NORMAL) {
    return 'Normal';
  }
  if (classification === CLASSIFICATION_WORKER_EXPIRING) {
    return 'Vencendo';
  }
  if (classification === CLASSIFICATION_WORKER_PENDING) {
    return 'Pendência';
  }
}

const validarEmail = function (email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const removerSessionStorage = function () {
  sessionStorage.removeItem('worker');
  sessionStorage.removeItem('ativos');
  sessionStorage.removeItem('usuario');
  sessionStorage.removeItem('empresa');
  sessionStorage.removeItem('relatorio');
  sessionStorage.removeItem('contratada');
  sessionStorage.removeItem('tipoAtuacao');
  sessionStorage.removeItem('supervisoes');
  sessionStorage.removeItem('empreendimento');
  sessionStorage.removeItem('unidadeOperacional');
  sessionStorage.removeItem('jobs');
  sessionStorage.removeItem('tipoAtivo');
}

const getOptionsStatusRegister = () => {
  return [
    {
      value: STATUS_REGISTER_ACTIVE,
      label: 'Ativo',
    },
    {
      value: STATUS_REGISTER_INACTIVE,
      label: 'Inativo',
    }
  ];
}
/**
 * Retorno  o label referente ao status do login informado
 * @param {*} status
 */
const getLabelStatusLogin = (status) => {
  return mapStatusUser.get(status);
}

const optionsPaginationTable = (total, customTotal, sizePerPage = 10) => {
  return {
    custom: true,
    sizePerPage,
    paginationSize: 4,
    pageStartIndex: 1,
    alwaysShowAllBtns: true, // Always show next and previous button
    withFirstAndLast: false, // Hide the going to First and Last page button
    // hideSizePerPage: true, // Hide the sizePerPage dropdown always
    hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
    firstPageText: 'Primeira',
    prePageText: 'Voltar',
    nextPageText: 'Próxima',
    lastPageText: 'Última',
    nextPageTitle: 'Primeira Página',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Pŕoxima Página',
    lastPageTitle: 'Última Página',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    totalSize: total,
  }
}

/**
 * Mostra msg de como é o padrão de senha
 */
const showMsgDefault = () => {
  let msgDefaultPass = [];

  msgDefaultPass.push('A senha deve possuir ao menos');
  msgDefaultPass.push('1 Letra maiúscula,');
  msgDefaultPass.push('1 Carácter especial,');
  msgDefaultPass.push('1 Número,');
  msgDefaultPass.push('8 Dígitos');

  return msgDefaultPass;

}

const getColorSystem = () => {
  const userConfig = getUserConfigs();
  if (userConfig && userConfig.color) {
    return userConfig.color;
  }
  return DEFAULT_COLOR;
}

const getStyleButton = (props) => {
  const userConfig = getUserConfigs();
  const styles = props ? props : {};

  if (userConfig && userConfig.color) {
    styles.backgroundColor = userConfig.color;
    styles.color = '#fff';
  }
  return styles;
}

const getLogo = () => {
  const userConfig = getUserConfigs();
  return require(`../assets/img/brand/${(userConfig && userConfig.logo) ? userConfig.logo : 'khomp.svg'}`);
}

const optionsToast = { };

module.exports = {
  getLabelStatusWorker, getColorStatusWorker, getLabelStatusDocument, validarEmail,
  STATUS_ACTIVE, STATUS_INACTIVE, STATUS_ANALYZE, STATUS_REJECTED, STATUS_DRAFT,
  STATUS_DOC_ACTIVE, STATUS_DOC_INACTIVE, STATUS_DOC_REJECTED, STATUS_DOC_NEW,
  STATUS_LOGIN_INACTIVE, STATUS_REGISTER_ACTIVE, STATUS_REGISTER_INACTIVE, getOptionsStatusRegister,
  TYPE_WORKER, TYPE_ACTIVE, STATUS_LOGIN_ACTIVE, STATUS_LOGIN_WAITING_PASS,
  STATUS_LOGIN_EXPIRE, STATUS_LOGIN_PASSWORD_EXPIRE, STATUS_LOGIN_ERR_PASSWORD,
  CLASSIFICATION_DOC_NORMAL, CLASSIFICATION_DOC_ANALYZE, CLASSIFICATION_DOC_PENDING, CLASSIFICATION_DOC_EXPIRING,
  CLASSIFICATION_WORKER_NORMAL, CLASSIFICATION_WORKER_EXPIRING, CLASSIFICATION_WORKER_PENDING,
  optionsToast,
  removerSessionStorage, getLabelStatusLogin, getLabelClassificationDocument, getLabelClassificationWorker,
  optionsPaginationTable, showMsgDefault,
  getStyleButton, getColorSystem, getLogo, DEFAULT_COLOR,
  formatMacAddress(mac) {
    if (mac) return mac.toUpperCase().replace(/[^\d|A-Z]/g, '').match(/.{1,2}/g || []).join(":");
    return mac;
  },
  validateImageSize(width, height) {
    if (width > 700 || height > 700) {
      const percent = 700 / (width < height ? width : height);
      const w = (width * percent).toFixed(2);
      const h = (height * percent).toFixed(2);
      return {
        width: w,
        height: h,
      }
    }
    return {
      width,
      height,
    }
  },
  translate(key) {
    const value = intl.get(key);
    if (value) return value;
    return key;
  },

  generateMacAddress(value) {
    const serial = value.toLowerCase().replace('k','');
    let serialHex = (+serial).toString(16);
      if (serialHex.length < 6) {
        serialHex = '0'.repeat(6 - serialHex.length) + serialHex.toUpperCase();
      }
    const macAddress = 'F80332' + serialHex;
    return macAddress;
  }
};

