const session = require('../util/session');
const types = require('../util/util');

const DASHBOARD = 0;
const WORKERS = 1;
const COMPANIES = 2;
const PLANTS = 3;
const OPERATIONAL_UNIT = 4;
const JOB_POSITION = 5;
const DOCUMENTS = 6;
const SUPERVISION = 7;
const GENERAL_REPORT = 8;
const BACKUP_DATABASE = 9;
const WORKERS_INFO = 10;
const ACTIVES = 11;
const CLIENTS = 12;
const CONTRACTEDS = 13;
const SUB_CONTRACTEDS = 14;
const USERS = 15;

const ALERT_NR15 = 16;

const PROFILE_SUPERUSER = 0;

const PROFILE_CLIENT = 1;
const PROFILE_CLIENT_VIEWER = 7;
const PROFILE_CLIENT_WORKER_ADMIN = 8;
const PROFILE_CLIENT_WORKER_VIEWER = 9;
const PROFILE_CLIENT_ACTIVE_ADMIN = 10;
const PROFILE_CLIENT_ACTIVE_VIEWER = 11;

const PROFILE_COMPANIE = 2;
const PROFILE_COMPANIE_VIEWER = 12;
const PROFILE_COMPANIE_WORKER_ADMIN = 13;
const PROFILE_COMPANIE_WORKER_VIEWER = 14;
const PROFILE_COMPANIE_ACTIVE_ADMIN = 15;
const PROFILE_COMPANIE_ACTIVE_VIEWER = 16

const PROFILE_CONTRACTED = 3;
const PROFILE_CONTRACTED_VIEWER = 17;
const PROFILE_CONTRACTED_WORKER_ADMIN = 18;
const PROFILE_CONTRACTED_WORKER_VIEWER = 19;
const PROFILE_CONTRACTED_ACTIVE_ADMIN = 20;
const PROFILE_CONTRACTED_ACTIVE_VIEWER = 21

const PROFILE_SUB_CONTRACTED = 4;
const PROFILE_SUB_CONTRACTED_VIEWER = 22;
const PROFILE_SUB_CONTRACTED_WORKER_ADMIN = 23;
const PROFILE_SUB_CONTRACTED_WORKER_VIEWER = 24;
const PROFILE_SUB_CONTRACTED_ACTIVE_ADMIN = 25;
const PROFILE_SUB_CONTRACTED_ACTIVE_VIEWER = 26;

const PROFILE_SUPERVISOR = 5;
const PROFILE_WORKER = 6;

const PROFILE_VIEW_ALERTS_NR15 = 27;

const PERMISSION_VIEW_ALL = 0;
const PERMISSION_VIEW_WORKERS = 1;
const PERMISSION_VIEW_ACTIVES = 2;

const profilesViwer = [PROFILE_CLIENT_VIEWER,
  PROFILE_CLIENT_WORKER_VIEWER,
  PROFILE_CLIENT_ACTIVE_VIEWER,
  PROFILE_COMPANIE_VIEWER,
  PROFILE_COMPANIE_WORKER_VIEWER,
  PROFILE_COMPANIE_ACTIVE_VIEWER,
  PROFILE_CONTRACTED_VIEWER,
  PROFILE_CONTRACTED_WORKER_VIEWER,
  PROFILE_CONTRACTED_ACTIVE_VIEWER,
  PROFILE_SUB_CONTRACTED_VIEWER,
  PROFILE_SUB_CONTRACTED_WORKER_VIEWER,
  PROFILE_SUB_CONTRACTED_ACTIVE_VIEWER,
  PROFILE_WORKER];

const profilesWorker = [
  PROFILE_CLIENT_WORKER_ADMIN, PROFILE_CLIENT_WORKER_VIEWER, PROFILE_COMPANIE_WORKER_ADMIN,
  PROFILE_COMPANIE_WORKER_VIEWER, PROFILE_CONTRACTED_WORKER_ADMIN,
  PROFILE_CONTRACTED_WORKER_VIEWER, PROFILE_SUB_CONTRACTED_WORKER_ADMIN,
  PROFILE_SUB_CONTRACTED_WORKER_VIEWER
];

const profilesActives = [
  PROFILE_CLIENT_ACTIVE_ADMIN, PROFILE_CLIENT_ACTIVE_VIEWER, PROFILE_COMPANIE_ACTIVE_ADMIN,
  PROFILE_COMPANIE_ACTIVE_VIEWER, PROFILE_CONTRACTED_ACTIVE_ADMIN,
  PROFILE_CONTRACTED_ACTIVE_VIEWER, PROFILE_SUB_CONTRACTED_ACTIVE_ADMIN,
  PROFILE_SUB_CONTRACTED_ACTIVE_VIEWER
];

const profilesClient = [
  PROFILE_CLIENT, PROFILE_CLIENT_VIEWER, PROFILE_CLIENT_WORKER_ADMIN,
  PROFILE_CLIENT_WORKER_VIEWER, PROFILE_CLIENT_ACTIVE_ADMIN, PROFILE_CLIENT_ACTIVE_VIEWER,
];

const profilesCompanie = [
  PROFILE_COMPANIE, PROFILE_COMPANIE_VIEWER, PROFILE_COMPANIE_WORKER_ADMIN,
  PROFILE_COMPANIE_WORKER_VIEWER, PROFILE_COMPANIE_ACTIVE_ADMIN, PROFILE_COMPANIE_ACTIVE_VIEWER,
];

const profilesContracted = [
  PROFILE_CONTRACTED, PROFILE_CONTRACTED_VIEWER, PROFILE_CONTRACTED_WORKER_ADMIN,
  PROFILE_CONTRACTED_WORKER_VIEWER, PROFILE_CONTRACTED_ACTIVE_ADMIN, PROFILE_CONTRACTED_ACTIVE_VIEWER,
];

const profilesSubConctracted = [
  PROFILE_SUB_CONTRACTED, PROFILE_SUB_CONTRACTED_VIEWER, PROFILE_SUB_CONTRACTED_WORKER_ADMIN,
  PROFILE_SUB_CONTRACTED_WORKER_VIEWER, PROFILE_SUB_CONTRACTED_ACTIVE_ADMIN, PROFILE_SUB_CONTRACTED_ACTIVE_VIEWER,
];

const profilesContractedSubActives = [
  PROFILE_CONTRACTED_ACTIVE_ADMIN, PROFILE_CONTRACTED_ACTIVE_VIEWER,
  PROFILE_SUB_CONTRACTED_ACTIVE_ADMIN, PROFILE_SUB_CONTRACTED_ACTIVE_VIEWER,
];

let map = new Map();
map.set(PROFILE_SUPERUSER,
  [DASHBOARD, WORKERS, COMPANIES, PLANTS, OPERATIONAL_UNIT, ACTIVES, CLIENTS, CONTRACTEDS, SUB_CONTRACTEDS,
    JOB_POSITION, DOCUMENTS, SUPERVISION, GENERAL_REPORT, BACKUP_DATABASE, WORKERS_INFO, USERS]);

map.set(PROFILE_CLIENT,
  [DASHBOARD, WORKERS, COMPANIES, PLANTS, OPERATIONAL_UNIT, ACTIVES, CONTRACTEDS, SUB_CONTRACTEDS,
    JOB_POSITION, DOCUMENTS, SUPERVISION, GENERAL_REPORT, WORKERS_INFO, USERS]);
map.set(PROFILE_CLIENT_VIEWER,
  [DASHBOARD, WORKERS, COMPANIES, PLANTS, OPERATIONAL_UNIT, ACTIVES, CONTRACTEDS, SUB_CONTRACTEDS,
    JOB_POSITION, DOCUMENTS, SUPERVISION, GENERAL_REPORT, WORKERS_INFO]);
map.set(PROFILE_CLIENT_WORKER_ADMIN,
  [DASHBOARD, WORKERS, COMPANIES, PLANTS, OPERATIONAL_UNIT, CONTRACTEDS, SUB_CONTRACTEDS,
    JOB_POSITION, DOCUMENTS, SUPERVISION, GENERAL_REPORT, WORKERS_INFO]);
map.set(PROFILE_CLIENT_WORKER_VIEWER,
  [DASHBOARD, WORKERS, COMPANIES, PLANTS, OPERATIONAL_UNIT, CONTRACTEDS, SUB_CONTRACTEDS,
    JOB_POSITION, DOCUMENTS, SUPERVISION, GENERAL_REPORT, WORKERS_INFO]);
map.set(PROFILE_CLIENT_ACTIVE_ADMIN,
  [DASHBOARD, COMPANIES, PLANTS, OPERATIONAL_UNIT, ACTIVES, CONTRACTEDS, SUB_CONTRACTEDS,
    JOB_POSITION, DOCUMENTS, SUPERVISION, GENERAL_REPORT, WORKERS_INFO]);
map.set(PROFILE_CLIENT_ACTIVE_VIEWER,
  [DASHBOARD, COMPANIES, PLANTS, OPERATIONAL_UNIT, ACTIVES, CONTRACTEDS, SUB_CONTRACTEDS,
    JOB_POSITION, DOCUMENTS, SUPERVISION, GENERAL_REPORT, WORKERS_INFO]);

map.set(PROFILE_COMPANIE,
  [DASHBOARD, WORKERS, COMPANIES, PLANTS, OPERATIONAL_UNIT, ACTIVES, CONTRACTEDS, SUB_CONTRACTEDS,
    JOB_POSITION, DOCUMENTS, SUPERVISION, GENERAL_REPORT, WORKERS_INFO, USERS]);
map.set(PROFILE_COMPANIE_VIEWER,
  [DASHBOARD, WORKERS, COMPANIES, PLANTS, OPERATIONAL_UNIT, ACTIVES, CONTRACTEDS, SUB_CONTRACTEDS,
    JOB_POSITION, DOCUMENTS, SUPERVISION, GENERAL_REPORT, WORKERS_INFO]);
map.set(PROFILE_COMPANIE_WORKER_ADMIN,
  [DASHBOARD, WORKERS, COMPANIES, PLANTS, OPERATIONAL_UNIT, CONTRACTEDS, SUB_CONTRACTEDS,
    JOB_POSITION, DOCUMENTS, SUPERVISION, GENERAL_REPORT, WORKERS_INFO]);
map.set(PROFILE_COMPANIE_WORKER_VIEWER,
  [DASHBOARD, WORKERS, COMPANIES, PLANTS, OPERATIONAL_UNIT, CONTRACTEDS, SUB_CONTRACTEDS,
    JOB_POSITION, DOCUMENTS, SUPERVISION, GENERAL_REPORT, WORKERS_INFO]);
map.set(PROFILE_COMPANIE_ACTIVE_ADMIN,
  [DASHBOARD, COMPANIES, PLANTS, OPERATIONAL_UNIT, ACTIVES, CONTRACTEDS, SUB_CONTRACTEDS,
    JOB_POSITION, DOCUMENTS, SUPERVISION, GENERAL_REPORT, WORKERS_INFO]);
map.set(PROFILE_COMPANIE_ACTIVE_VIEWER,
  [DASHBOARD, COMPANIES, PLANTS, OPERATIONAL_UNIT, ACTIVES, CONTRACTEDS, SUB_CONTRACTEDS,
    JOB_POSITION, DOCUMENTS, SUPERVISION, GENERAL_REPORT, WORKERS_INFO]);

map.set(PROFILE_CONTRACTED, [WORKERS, WORKERS_INFO, ACTIVES, CONTRACTEDS, SUB_CONTRACTEDS, USERS]);
map.set(PROFILE_CONTRACTED_VIEWER, [WORKERS, WORKERS_INFO, ACTIVES, CONTRACTEDS, SUB_CONTRACTEDS]);
map.set(PROFILE_CONTRACTED_WORKER_ADMIN, [WORKERS, WORKERS_INFO, CONTRACTEDS, SUB_CONTRACTEDS]);
map.set(PROFILE_CONTRACTED_WORKER_VIEWER, [WORKERS, WORKERS_INFO, CONTRACTEDS, SUB_CONTRACTEDS]);
map.set(PROFILE_CONTRACTED_ACTIVE_ADMIN, [ACTIVES, CONTRACTEDS, SUB_CONTRACTEDS]);
map.set(PROFILE_CONTRACTED_ACTIVE_VIEWER, [ACTIVES, CONTRACTEDS, SUB_CONTRACTEDS]);

map.set(PROFILE_SUB_CONTRACTED, [WORKERS, WORKERS_INFO, ACTIVES, SUB_CONTRACTEDS, USERS]);
map.set(PROFILE_SUB_CONTRACTED_VIEWER, [WORKERS, WORKERS_INFO, ACTIVES, SUB_CONTRACTEDS]);
map.set(PROFILE_SUB_CONTRACTED_WORKER_ADMIN, [WORKERS, WORKERS_INFO, SUB_CONTRACTEDS]);
map.set(PROFILE_SUB_CONTRACTED_WORKER_VIEWER, [WORKERS, WORKERS_INFO, SUB_CONTRACTEDS]);
map.set(PROFILE_SUB_CONTRACTED_ACTIVE_ADMIN, [ACTIVES, SUB_CONTRACTEDS]);
map.set(PROFILE_SUB_CONTRACTED_ACTIVE_VIEWER, [ACTIVES, SUB_CONTRACTEDS]);

map.set(PROFILE_VIEW_ALERTS_NR15, [ALERT_NR15]);

map.set(PROFILE_SUPERVISOR, [WORKERS, SUPERVISION, JOB_POSITION, DASHBOARD, DOCUMENTS,
  GENERAL_REPORT, WORKERS_INFO, ACTIVES]);
map.set(PROFILE_WORKER, [WORKERS_INFO]);

/**
 * Retorna se a pagina é valida para o perfil informado
 * @param {*} profile
 * @param {*} page
 */
const isPageValid = function (page) {
  const user = session.getUserConfigs();
  if (user.status === types.STATUS_LOGIN_PASSWORD_EXPIRE) {
    return '/usuarios/alterarSenha';
  }
  const pages = map.get(user.profile);
  if (!pages.includes(page)) {
    return '/login';
  }
  return null;
}

/**
 * Retorna true se o perfil for apenas de visualizacao de registros
 * @param {*} profile
 */
const isProfileViewer = (profile) => profilesViwer.includes(profile);

/**
 * Retorna true se o perfil for relacionado a clients
 * @param {*} profile
 */
const isProfileClient = (profile) => profilesClient.includes(profile);

/**
 * Retorna true se o perfil for relacionado a Companies
 * @param {*} profile
 */
const isProfileCompanie = (profile) => profilesCompanie.includes(profile);

/**
 * Retorna true se o perfil for relacionado a Contracteds
 * @param {*} profile
 */
const isProfileContracted = (profile) => profilesContracted.includes(profile);

/**
 * Retorna true se o perfil for relcionado a SubContracteds
 * @param {*} profile
 */
const isProfileSubContracted = (profile) => profilesSubConctracted.includes(profile);

/**
 * Retorna true se o perfil for de uma contratada ou subcontratda com visualizacao de ativos,
 * pois para o redirecionamento do login é necessário identificar se o usuario
 * não pode acessar a pagina de profissionais
 * @param {*} profile
 */
const isProfilesActivesContracteds = (profile) => profilesContractedSubActives.includes(profile);

const isProfileAdmin = (profile) => {
  return profile === PROFILE_SUPERUSER ||
    profile === PROFILE_CLIENT || profile === PROFILE_COMPANIE;
}

/**
 * Retorna 0 se for um perfil geral, 1 se for perfil apenas worker
 * e 2 se for apenas active
 * @param {*} profile
 * @param {*} worker
 */
const getProfileType = (profile) => {
  if (profilesWorker.includes(profile)) {
    return PERMISSION_VIEW_WORKERS;
  }
  if (profilesActives.includes(profile)) {
    return PERMISSION_VIEW_ACTIVES;
  }
  return PERMISSION_VIEW_ALL;
}

const isEnablersVisualConfig = () => {
  return Boolean(session.getUserConfigs().enablersVisualConfig);
}

module.exports = {
  DASHBOARD, WORKERS, COMPANIES, PLANTS, OPERATIONAL_UNIT, JOB_POSITION, DOCUMENTS,
  SUPERVISION, GENERAL_REPORT, BACKUP_DATABASE, WORKERS_INFO, ACTIVES, CLIENTS,
  CONTRACTEDS, SUB_CONTRACTEDS, USERS,
  PROFILE_SUPERUSER, PROFILE_CLIENT, PROFILE_COMPANIE, PROFILE_CONTRACTED,
  PROFILE_SUB_CONTRACTED, PROFILE_SUPERVISOR, PROFILE_WORKER,
  PROFILE_CLIENT_VIEWER, PROFILE_CLIENT_WORKER_ADMIN, PROFILE_CLIENT_WORKER_VIEWER,
  PROFILE_CLIENT_ACTIVE_ADMIN, PROFILE_CLIENT_ACTIVE_VIEWER,
  PROFILE_COMPANIE_VIEWER, PROFILE_COMPANIE_WORKER_ADMIN, PROFILE_COMPANIE_WORKER_VIEWER,
  PROFILE_COMPANIE_ACTIVE_ADMIN, PROFILE_COMPANIE_ACTIVE_VIEWER,
  PROFILE_CONTRACTED_VIEWER, PROFILE_CONTRACTED_WORKER_ADMIN, PROFILE_CONTRACTED_WORKER_VIEWER,
  PROFILE_CONTRACTED_ACTIVE_ADMIN, PROFILE_CONTRACTED_ACTIVE_VIEWER,
  PROFILE_SUB_CONTRACTED_VIEWER, PROFILE_SUB_CONTRACTED_WORKER_ADMIN, PROFILE_SUB_CONTRACTED_WORKER_VIEWER,
  PROFILE_SUB_CONTRACTED_ACTIVE_ADMIN, PROFILE_SUB_CONTRACTED_ACTIVE_VIEWER,
  PROFILE_VIEW_ALERTS_NR15,
  PERMISSION_VIEW_ALL, PERMISSION_VIEW_WORKERS, PERMISSION_VIEW_ACTIVES,
  profilesContracted, profilesSubConctracted, profilesCompanie,
  isPageValid, isProfileViewer, isProfileClient, isProfileCompanie,
  isProfileContracted, isProfileSubContracted, isProfilesActivesContracteds, getProfileType,
  isProfileAdmin, isEnablersVisualConfig
}

