import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from "reactstrap";
// import { Link } from 'react-router-dom';

import session from "../../util/session";

const propTypes = {
  notif: PropTypes.bool,
  accnt: PropTypes.bool,
  tasks: PropTypes.bool,
  mssgs: PropTypes.bool,
};
const defaultProps = {
  notif: false,
  accnt: false,
  tasks: false,
  mssgs: false,
};

class DefaultHeaderDropdown extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.state = {
      dropdownOpen: false,
      username: "",
      name: "",
      empreendimento: "",
      operacao: "",
      supervisor: "",
      worker_id: "",
      avatar: "assets/img/avatar.png",
      width: 0,
      height: 0,
      urlRedirect: '',
    };
  }

  toggle() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }

  componentWillMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
    try {
      const userConfigs = session.getUserConfigs();
      if (userConfigs) {
        this.setState({
          username: userConfigs.email,
          name: userConfigs.name,
          urlRedirect: userConfigs.logo ? `/${userConfigs.logo.split('.')[0]}` : '',
        });

        if (userConfigs.userFile && userConfigs.userFile !== "") {
          this.setState({
            avatar: process.env.REACT_APP_URI + userConfigs.userFile,
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  }

  dropAccnt() {
    return (
      <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle}>
        <Row style={{ paddingRight: "40px" }}>
          {this.state.width > 650 && (
            <div>
              <span
                style={{
                  paddingRight: "15px",
                  textAlign: "right",
                  color: "white",
                }}
              >
                {this.state.name}
              </span>
              <br></br>
              <span
                style={{
                  paddingRight: "15px",
                  textAlign: "right",
                  color: "white",
                }}
              >
                {this.state.username}
              </span>
            </div>
          )}
          <DropdownToggle nav>
            <img
              id="imgRound"
              name="imgRound"
              alt="avatar"
              src={
                this.state.avatar === null
                  ? "assets/img/avatar.png"
                  : `${this.state.avatar}?${new Date().getTime()}`
              }
              className="rounded float-right"
              style={{
                maxWidth: "45px",
                maxHeight: "45px",
                objectFit: "cover",
                objectPosition: "top",
              }}
            />
          </DropdownToggle>
        </Row>

        <DropdownMenu left>
          <DropdownItem
            id="changePwdBtn"
            name="changePwdBtn"
            href="#/usuarios/alterarSenha"
          >
            <i className="fa fa-key"></i> Alterar senha
          </DropdownItem>
          <DropdownItem
            id="changeDatasBtn"
            name="changeDatasBtn"
            href="#/usuarios/alterarDados"
          >
            <i className="fa fa-edit"></i> Alterar dados
          </DropdownItem>
          <DropdownItem
            id="logOutBtn"
            name="logOutBtn"
            href={`#/login${this.state.urlRedirect}`}
            onClick={() => {
              this.signOut();
            }}
          >
            <i className="fa fa-lock"></i> Logout
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  }

  signOut() {
    sessionStorage.clear();
  }

  render() {
    return this.dropAccnt();
  }
}

DefaultHeaderDropdown.propTypes = propTypes;
DefaultHeaderDropdown.defaultProps = defaultProps;

export default DefaultHeaderDropdown;
