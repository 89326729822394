import React, { Component } from "react";
import {
  Button,
  Card,
  CardBody,
  CardGroup,
  Col,
  Container,
  Form,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
  Label,
  UncontrolledTooltip,
  InputGroupText,
} from "reactstrap";
import { ToastContainer, toast } from "react-toastify";
import LaddaButton, { EXPAND_LEFT } from "react-ladda";

// import logo from '../../../assets/img/brand/logo-khomp.png'
import mpa from "../../../assets/img/brand/MPA-completa_2.png";

import profiles from "../../../util/profiles";
import encryptUtil from "../../../util/encryptUtil";
import session from "../../../util/session";
import util from "../../../util/util";

import "ladda/dist/ladda-themeless.min.css";
import "react-toastify/dist/ReactToastify.css";

const optionsToast = {
  position: "top-center",
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
};

class Login extends Component {
  componentDidMount() {
    sessionStorage.clear();
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);

    const client = this.props.match.params.client;
    if (client) {
      if (client === "enablers") {
        this.setState({
          color: "#1E8A9A",
          logo: require("../../../assets/img/brand/enablers.svg"),
          client,
        });
      }
    }

    const userName = localStorage.getItem("userName");
    const password = localStorage.getItem("password");

    this.setState({
      loading_scr: true,
      passwd: password ? encryptUtil.decrypt(password) : "",
      usuario: userName ? encryptUtil.decrypt(userName) : "",
    });
  }

  componentDidUpdate() {
    const client = this.props.match.params.client;
    if (client !== this.state.client) {
      let color = "#3a7243";
      let logo = require("../../../assets/img/brand/logo-khomp.png");
      if (client) {
        if (client === "enablers") {
          color = "#1E8A9A";
          logo = require("../../../assets/img/brand/enablers.svg");
        }
      }
      this.setState({
        color,
        logo,
        client,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  constructor(props) {
    super(props);

    this.forget_pwd = this.forgetPassword.bind(this);
    this.encodeFormData = this.encodeFormData.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.signIn = this.signIn.bind(this);

    this.state = {
      loading_scr: false,
      loading_btn: false,
      showBtn: true,
      showForgotPass: true,
      showCheckBox: true,
      showSavePass: true,
      showMsgUsuario: false,
      keep_login:
        localStorage.getItem("keep_login") != null
          ? localStorage.getItem("keep_login")
          : false,
      passwd: "",
      usuario: "",
      width: 0,
      height: 0,
      client: "khomp",
      color: "#3a7243",
      logo: require("../../../assets/img/brand/logo-khomp.png"),
    };
  }
  updateWindowDimensions() {
    this.setState(
      { width: window.innerWidth, height: window.innerHeight },
      () => {}
    );
  }

  toggle(name) {
    this.setState({
      [name]: !this.state[name],
      progress: 0.5,
    });
  }

  encodeFormData(data) {
    return Object.keys(data)
      .map((key) => encodeURI(key) + "=" + encodeURI(data[key]))
      .join("&");
  }

  signIn = async (e) => {
    e.preventDefault();
    const usuario = this.state.usuario;
    const senha = this.state.passwd;
    let token = "";
    let userConfigs = {};
    let worker = {};
    let logged = false;

    if (!usuario || !senha) {
      toast.error(
        <div>
          {" "}
          <h4>
            {"FALHA NA AUTENTICAÇÃO"}
            <br />{" "}
          </h4>
          {"Preencha os campos de e-mail e senha"}
        </div>,
        optionsToast
      );
      this.setState({ loading_btn: false });
      return;
    }

    this.toggle("loading_btn");
    const formData = {
      username: usuario,
      password: senha,
    };
    const url = process.env.REACT_APP_URI + "/loginWeb";

    const resp = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    });

    const res = await resp.json();
    const msg = res.msg;

    if (msg) {
      toast.error(
        <div>
          {" "}
          <h4>
            {"FALHA NA AUTENTICAÇÃO"}
            <br />{" "}
          </h4>
          {msg}
        </div>,
        optionsToast
      );
      this.setState({ loading_btn: false });
    } else {
      const loginModel = res.details;
      worker = loginModel.workerid;

      if (loginModel.status === util.STATUS_LOGIN_EXPIRE) {
        this.setState({ loading_btn: false });
        this.setState({
          showBtn: false,
          showCheckBox: false,
          showForgotPass: false,
          showSavePass: false,
          showMsgUsuario: true,
        });
        return;
      }
      userConfigs = {
        id: loginModel.id,
        name: loginModel.name,
        email: loginModel.username,
        profile: loginModel.accessLevel,
        status: loginModel.status,
        letter: loginModel.letter,
      };
      if (loginModel.clientId) {
        userConfigs.client = loginModel.clientId;
      }

      if (loginModel.companieId) {
        userConfigs.companie = loginModel.companieId;
      }

      if (loginModel.contractedId) {
        userConfigs.contracted = loginModel.contractedId;
      }

      if (loginModel.subContractedId) {
        userConfigs.subContracted = loginModel.subContractedId;
      }
      if (loginModel.status) {
        userConfigs.status = loginModel.status;
      }

      if (loginModel.workerId) {
        userConfigs.worker = loginModel.workerId;
      }
      if (loginModel.workerLetter) {
        userConfigs.workerLetter = true;
      }
      if (loginModel.areas) {
        userConfigs.areas = true;
      }
      if (loginModel.motionDetection) {
        userConfigs.motionDetection = true;
      }
      if (loginModel.presenceDetection) {
        userConfigs.presenceDetection = true;
      }
      if (loginModel.enablersVisualConfig) {
        userConfigs.enablersVisualConfig = true;
      }
      if (loginModel.color) userConfigs.color = loginModel.color;
      if (loginModel.logo) userConfigs.logo = loginModel.logo;

      token = res.token;
      logged = true;
    }

    if (logged) {
      await this.persistValues(usuario, senha, token, userConfigs, worker);
      if (
        profiles.isProfileContracted(+userConfigs.profile) ||
        profiles.isProfileSubContracted(+userConfigs.profile)
      ) {
        if (profiles.isProfilesActivesContracteds(+userConfigs.profile)) {
          this.props.history.push("/ativos");
        } else {
          this.props.history.push("/pessoas");
        }
      } else if (+userConfigs.profile === +profiles.PROFILE_WORKER) {
        this.props.history.push({
          pathname: "pessoas/informacoes",
          query: { worker: userConfigs.worker },
        });
      } else if (+userConfigs.profile === +profiles.PROFILE_VIEW_ALERTS_NR15) {
        this.props.history.push("/dashboard_alerts");
      } else {
        this.props.history.push("/dashboard");
      }
    }
  };

  async persistValues(usuario, senha, token, userConfigs, worker) {
    session.createSessionToken();

    if (this.state.keep_login) {
      localStorage.setItem("userName", encryptUtil.encrypt(usuario));
      localStorage.setItem("password", encryptUtil.encrypt(senha));
    } else {
      localStorage.removeItem("userName");
      localStorage.removeItem("password");
    }
    localStorage.setItem("keep_login", this.state.keep_login);
    sessionStorage.setItem("token", token);
    sessionStorage.setItem(
      "ttv",
      encryptUtil.encrypt(new Date().getTime().toString())
    );
    sessionStorage.setItem(
      "userConfig",
      encryptUtil.encrypt(JSON.stringify(userConfigs))
    );
  }

  forgetPassword(usuario) {
    if (usuario === "") {
      toast.error(
        <div>
          {" "}
          <h4>
            {"POR FAVOR PREENCHER O USUÁRIO"}
            <br />{" "}
          </h4>
          {"É necessário informar o usuário"}
        </div>,
        optionsToast
      );
      return;
    }
    let formData = {
      username: usuario,
    };
    const url = process.env.REACT_APP_URI + "/reset_pwd";
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "*/*",
        Connection: "keep-alive",
        "Accept-Encoding": "gzip, deflate",
      },
      body: JSON.stringify(formData),
    })
      .then((data) => data.json())
      .then((res) => {
        if (res && res.status === "SUCCESS") {
          toast.success(
            <div>
              {" "}
              <h4>
                {"Solicitação troca de senha enviada!"}
                <br />{" "}
              </h4>
              {"Um e-mail para criar uma nova senha foi enviado"}
              <br />
              {"VERIFIQUE SUA CAIXA DE E-MAIL"}
            </div>,
            optionsToast
          );
          this.setState({ usuario: "" });
        } else {
          toast.error(
            <div>
              {" "}
              <h4>
                {"FALHA NA SOLICITAÇÂO"}
                <br />{" "}
              </h4>
              {res.details}
            </div>,
            optionsToast
          );
        }
      });
  }

  render() {
    return (
      <div
        className="app flex-row align-items-center animated fadeIn"
        style={{ backgroundColor: this.state.color }}
      >
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable={false}
          pauseOnHover
        />
        <Container>
          <Row
            className="justify-content-center"
            style={{ marginBottom: "2.5em", paddingTop: "-2em" }}
          >
            <Col
              xs="12"
              sm="12"
              md="5"
              lg="5"
              xl="5"
              className="align-content-center"
            >
              <Row className="justify-content-center">
                <img
                  src={mpa}
                  alt="mpa"
                  style={{ width: "100%", height: "100%" }}
                  mode="fit"
                />
              </Row>
              <Row className="justify-content-center">
                <img
                  src={this.state.logo}
                  alt="logo"
                  style={{ width: "50%", height: "100%" }}
                  mode="fit"
                />
              </Row>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col md="6">
              <CardGroup>
                <Card className="p-4">
                  <CardBody>
                    <Form style={{ fontSize: "1vmax" }}>
                      <InputGroup className="mb-3">
                        <InputGroupAddon addonType="prepend"></InputGroupAddon>
                        <Input
                          id="userEntry"
                          name="userEntry"
                          type="text"
                          value={this.state.usuario}
                          onChange={(user) =>
                            this.setState({ usuario: user.target.value })
                          }
                          placeholder="E-Mail"
                          autoComplete="username"
                        />
                      </InputGroup>
                      <InputGroup className="mb-2">
                        <Input
                          id="passEntry"
                          name="passEntry"
                          type="password"
                          placeholder="Senha"
                          autoComplete="current-password"
                          value={this.state.passwd}
                          onChange={(senha) =>
                            this.setState({ passwd: senha.target.value })
                          }
                        />
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText
                            style={{
                              borderTopRightRadius: "10px",
                              borderBottomRightRadius: "10px",
                            }}
                          >
                            <i id="explainPassword" className="fa fa-info"></i>
                            <UncontrolledTooltip
                              style={{
                                backgroundColor: "rgb(255, 255, 255)",
                                fontWeight: "bold",
                              }}
                              placement="right"
                              target="explainPassword"
                            >
                              <p style={{ color: "rgb(0, 0, 158)" }}>
                                Após 5 tentativas falhas o usuário será
                                bloqueado!
                              </p>
                            </UncontrolledTooltip>
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      <InputGroup className="mb-3">
                        {this.state.showMsgUsuario && (
                          <div
                            style={{
                              width: "100%",
                              height: "40px",
                              textAlign: "center",
                              backgroundColor: "#D12F0D",
                              borderRadius: "10px",
                            }}
                          >
                            <h4 style={{ color: "#ffffff" }}>
                              Seu usuário foi bloqueado!
                            </h4>
                            <p
                              style={{
                                backgroundColor: "#D12F0D",
                                borderBottomLeftRadius: "10px",
                                borderBottomRightRadius: "10px",
                                color: "#ffffff",
                              }}
                            >
                              Este usuário ficou inativo por 6 meses.
                              <br />
                              Entre em contato com o administrador do sistema,
                              para desbloquear seu usuário.
                            </p>
                          </div>
                        )}
                        {this.state.showCheckBox && (
                          <Input
                            style={{ marginLeft: "0px" }}
                            type="checkbox"
                            id="saveCredChk"
                            name="saveCredChk"
                            value={this.state.keep_login}
                            defaultChecked={this.state.keep_login}
                            onChange={(keep) =>
                              this.setState({ keep_login: keep.target.checked })
                            }
                          />
                        )}
                        {this.state.showSavePass && (
                          <Label
                            style={{ marginLeft: "15px", fontSize: "14px" }}
                            check
                            className="form-check-label"
                            htmlFor="checkbox1"
                          >
                            Salvar Credenciais
                          </Label>
                        )}
                      </InputGroup>
                      <Row
                        className="justify-content-center"
                        style={{ paddingBottom: "1em" }}
                      >
                        {this.state.showBtn && (
                          <LaddaButton
                            id="enterBtn"
                            name="enterBtn"
                            className="btn btn-ladda"
                            loading={this.state.loading_btn}
                            onClick={this.signIn}
                            data-color="green"
                            data-style={EXPAND_LEFT}
                            style={{
                              width: "100%",
                              height: "40px",
                              backgroundColor: this.state.color,
                              color: "white",
                              fontWeight: "bolder",
                            }}
                          >
                            Entrar
                          </LaddaButton>
                        )}
                      </Row>
                      <Row className="justify-content-center">
                        {this.state.showForgotPass && (
                          <Button
                            color="link"
                            className="px-0"
                            onClick={this.forgetPassword.bind(
                              this,
                              this.state.usuario
                            )}
                          >
                            Esqueceu sua senha?
                          </Button>
                        )}
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </CardGroup>
              <Row style={{ paddingTop: "1em" }}>
                <Col xs="6">
                  <span style={{ fontSize: "13px", color: "white" }}></span>
                </Col>
                <Col xs="6" className="text-right">
                  <span style={{ fontSize: "13px", color: "white" }}>
                    {process.env.REACT_APP_VERSION_SYSTEM}
                  </span>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Login;
