import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";
import { AppFooter, AppHeader } from "@coreui/react";
import { Card, CardBody, Container } from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";

import HeaderNoOptions from "../../containers/DefaultLayout/HeaderNoOptions";
import DefaultFooter from "../../containers/DefaultLayout/DefaultFooter";
import ToastAlert from "./toastAlert";

import { getRequestOptions } from "../../util/session";
import { DEFAULT_COLOR } from "../../util/util";
import sound from "../../assets/conclusive-message-tone.mp3";

import "./styles.css";
import "react-toastify/dist/ReactToastify.css";
import "react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

const optionsToast = {
  position: "top-center",
  autoClose: 15000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
};

export default function NotifyAlertNr15(params) {
  const [color, setColor] = useState("");
  const [logo, setLogo] = useState("");

  const [alerts, setAlerts] = useState([]);
  const [showReader, setShowReader] = useState(false);

  useEffect(() => {
    setShowReader(params.location.pathname !== "/dashboardAlert");
  }, []);

  useEffect(() => {
    setColor(DEFAULT_COLOR);
    setLogo(require(`../../assets/img/brand/khomp.svg`));
    const timer = setInterval(async () => {
      const values = await findValues();
      if (values) {
        validateData(values.alerts);
        setAlerts(values.alerts);
        sessionStorage.setItem("token", values.token);
      }
    }, 30000);

    return () => clearInterval(timer);
  }, [alerts]);

  const findValues = async () => {
    const url = `${process.env.REACT_APP_URI}/nr/expiringTime`;
    try {
      const response = await fetch(url, getRequestOptions());
      if (response.status === 200) {
        const values = await response.json();

        return values;
      } else if (response.status === 401) {
        sessionStorage.clear();
      } else {
        toast.error("Não foi possível retornar os dados", optionsToast);
      }
    } catch (error) {
      toast.error("Não foi possível retornar os dados", optionsToast);
    }
  };

  const validateData = (newValues) => {
    const newNames = [];
    for (const newValue of newValues) {
      const isAlert = alerts.find(
        (alert) => alert.period_id === newValue.period_id
      );
      if (!isAlert) {
        newNames.push(newValue.worker);
      }
    }

    if (newNames.length > 0) {
      new Audio(sound).play();
      toast.error(<ToastAlert names={newNames} />, optionsToast);
    }
  };

  const headerStyle = {
    backgroundColor: "rgb(241, 242, 247)",
    fontSize: "1.3em",
    textAlign: "center",
  };
  const columns = [
    {
      dataField: "worker",
      text: "Profissional",
      headerStyle,
    },
    {
      dataField: "plants",
      text: "Plantas",
      headerStyle,
      formatter: (plants) => plants.join(", "),
    },
    {
      dataField: "created_at",
      text: "Hora do alerta",
      headerStyle: { ...headerStyle, width: "200px" },
      formatter: (cell) => new Date(cell).toLocaleString(),
    },
  ];

  return (
    <div className="app">
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable={false}
        pauseOnHover
      />
      {showReader && (
        <AppHeader fixed style={{ backgroundColor: color }}>
          <HeaderNoOptions color={color} logo={logo} />
        </AppHeader>
      )}
      <div className="app-body">
        <main
          className="main"
          style={{ backgroundColor: "white", marginLeft: "0px" }}
        >
          <Container fluid style={{ marginTop: showReader ? "2em" : "0em" }}>
            <Card>
              <span
                style={{
                  fontSize: "25px",
                  fontWeight: "bold",
                  color: color,
                  marginLeft: "20px",
                }}
              >
                Alertas de Ciclos
              </span>
              <CardBody>
                <div style={{ marginTop: "1em" }}>
                  <BootstrapTable
                    keyField="period_id"
                    data={alerts}
                    columns={columns}
                    hover
                    condensed
                    bordered={false}
                    noDataIndication="Sem dados para exibir"
                    rowClasses="tableCell"
                  />
                </div>
              </CardBody>
            </Card>
          </Container>
        </main>
      </div>
      <AppFooter>
        <DefaultFooter />
      </AppFooter>
    </div>
  );
}
